import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { COLORS } from "../../constants/colors";
import {
  Button,
  Card,
  Chip,
  CircularProgress,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  useVendor,
  useVendorMemoTesterTrigger,
  useVendorMutation,
} from "../../hooks/useVendors";
import { useUploadImage } from "../../hooks/useUploadImage";
import { SubcategorySelect } from "./SubcategorySelect";
import {
  VendorLayout,
  VendorHeader,
  VendorContent,
  VendorSidebar,
} from "./VendorLayout";
import { VendorSelect } from "./VendorSelect";
import { SidebarModuleCheck, SidebarModule } from "./SidebarModule";
import { CertificationButton } from "./CertificationButton";
import {
  useCreateVendorCertificationMutation,
  useUpdateVendorCertificationMutation,
} from "../../hooks/useVendorCertifications";
import {
  useCreateVendorOfferMutation,
  useUpdateVendorOfferMutation,
} from "../../hooks/useVendorOffers";
import { CertificationModule } from "./CertificationModule";
import { separateObjectByPrefix } from "./utils";
import { OfferModal } from "./OfferModal";
import { ImageUpload } from "../ImageUpload";

import { MemoTester } from "./MemoTester";

const CENTER_COLUMN_STYLES = {
  width: "100%",
};

const vendorMetaAttributesRequired = ["description", "avatarURL"];

export const Vendor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openMemotesterModal, setOpenMemotesterModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [vendorId, setVendorId] = useState(() =>
    id === "new" ? undefined : id
  );
  const selectedOffer = searchParams.get("offer");
  const {
    data,
    isLoading,
    refetch,
    error: vendorFetchError,
  } = useVendor(vendorId, {
    enabled: !!vendorId,
  });
  const vendor = useMemo(() => data?.vendor ?? {}, [data]);
  const {
    mutateAsync: updateVendor,
    isLoading: isVendorUpdating,
    error: vendorUpdateError,
  } = useVendorMutation();
  const { mutateAsync: uploadImage } = useUploadImage();
  const { mutateAsync: createOffer } = useCreateVendorOfferMutation(vendorId);
  const { mutateAsync: updateOffer } = useUpdateVendorOfferMutation(vendorId);
  const { mutateAsync: enhanceVendor, data: memoTesterRequestData } =
    useVendorMemoTesterTrigger(vendorId);

  const [storedError, setError] = useState();
  const [changes, setChanges] = useState({});
  const handleChange = (e) => {
    setError(null);
    setChanges({ ...changes, [e.target.name]: e.target.value });
  };
  const hasNewOffer = useMemo(() => {
    return Object.keys(changes).some(
      (key) => key.startsWith("offer") && key.endsWith("new")
    );
  }, [changes]);
  const selectOffer = (offerId) => {
    setSearchParams(
      (params) => {
        params.set("offer", offerId);
        return params;
      },
      { replace: true }
    );
  };
  const closeOffer = () => {
    setSearchParams(
      (params) => {
        params.delete("offer");
        return params;
      },
      { replace: true }
    );
  };

  useEffect(() => {
    if (id === "new") {
      setVendorId(undefined);
    } else {
      setVendorId(id);
    }
  }, [id]);

  const derivedError = useMemo(() => {
    const doesNotHaveRequiredVendorMetaData = vendorMetaAttributesRequired.some(
      (key) => {
        return !changes[key] && !vendor?.VendorMeta?.[key];
      }
    );

    if (changes.isSustainableVendor && doesNotHaveRequiredVendorMetaData) {
      return new Error(
        "Sustainable vendors require description, cover image, and avatar"
      );
    }

    if (
      hasNewOffer &&
      (!changes["offerTitle:new"] || !changes["offerStartDate:new"])
    ) {
      return new Error("Start date and title are required for new offers");
    }

    return null;
  }, [changes, hasNewOffer, vendor?.VendorMeta]);

  useEffect(() => {
    setError(vendorFetchError ?? vendorUpdateError);
  }, [vendorFetchError, vendorUpdateError]);

  const error = storedError ?? derivedError;

  const hasChanges = useMemo(() => {
    if (!vendor) {
      return false;
    }
    return Object.entries(changes).some(([key, value]) => {
      if (key === "tags") {
        return value !== vendor[key]?.join(", ");
      }

      return vendor?.VendorMeta?.[key] !== value;
    });
  }, [changes, vendor]);

  const clearChanges = () => {
    setChanges({});
  };

  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    const imageType = e.target.name;
    const resp = await uploadImage({ vendorId, imageType, image: file });
    setChanges({ ...changes, [imageType]: resp.assetUrl });
  };

  const { mutateAsync: createVendorCertification } =
    useCreateVendorCertificationMutation({
      vendorId,
    });

  const {
    mutateAsync: updateVendorCertification,
    isLoading: isCertificationUpdating,
  } = useUpdateVendorCertificationMutation({
    vendorId,
  });

  const onSaveChanges = async () => {
    const changeSegments = separateObjectByPrefix(changes, [
      "certification",
      "offer",
    ]);
    const vendorChanges = changeSegments.rest ?? {};
    const certificationChangesById = changeSegments.certification ?? {};
    const offerChangesById = changeSegments.offer ?? {};

    const hasVendorChanges = Object.keys(vendorChanges).length > 0;
    const hasCertificationChanges =
      Object.keys(certificationChangesById).length > 0;
    const hasOfferChanges = Object.keys(offerChangesById).length > 0;

    if (hasCertificationChanges) {
      await Promise.allSettled(
        Object.entries(certificationChangesById).map(async ([id, changes]) =>
          updateVendorCertification({
            certificationId: id,
            ...changes,
          })
        )
      );
    }

    if (hasOfferChanges) {
      const newOffers = Object.entries(offerChangesById).filter(
        ([id]) => id === "new"
      );
      const existingOffers = Object.entries(offerChangesById).filter(
        ([id]) => id !== "new"
      );
      await Promise.allSettled(
        existingOffers.map(async ([id, changes]) => {
          await updateOffer({ id, changes });
        })
      );
      await Promise.allSettled(
        newOffers.map(async ([, changes]) => {
          await createOffer(changes);
        })
      );
    }

    if (hasVendorChanges) {
      const resp = await updateVendor({
        vendorId,
        update: changes,
      });
      if (resp?.vendor?.id && !vendorId) {
        navigate(`/vendors/${resp.vendor.id}`);
        return;
      }
    }

    if (hasVendorChanges || hasCertificationChanges || hasOfferChanges) {
      setChanges({});
      await refetch();
    }
  };

  if (isLoading && vendorId) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <VendorLayout key={vendorId}>
        <VendorHeader>
          <Box columnGap={2} display="flex" alignItems={"center"}>
            {vendorId && (<big>ID: {vendorId}</big>)}
            {vendorId && (
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  await enhanceVendor();
                  setOpenMemotesterModal(true);
                }}
              >
                Memo Tester
              </Button>
            )}
            {vendorId && vendor?.isSustainableVendor && (
              <a
                href={`https://brands.thecommons.earth/brands/${vendor.id
                }/brand?n=${+new Date()}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button variant="outlined" size="small">
                  Brand Page
                </Button>
              </a>
            )}
          </Box>
        </VendorHeader>
        <VendorContent>
          <ImageUpload
            image={changes.imageURL ?? vendor?.VendorMeta?.imageURL}
            onChange={handleChange}
            imageAlt={vendor?.vendorName}
            sx={{
              mb: 2,
              ...CENTER_COLUMN_STYLES,
            }}
            name="imageURL"
          />
          <Box display="flex" flexDirection="row" alignItems="center">
            <ImageUpload
              image={changes.avatarURL ?? vendor?.VendorMeta?.avatarURL}
              onChange={handleChange}
              imageAlt={vendor?.vendorName}
              sx={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
              }}
              name="avatarURL"
            />
            <Box flex="1">
              <Box pl={2}>
                <Box pb={2}>
                  <TextField
                    pb={2}
                    name="vendorName"
                    value={changes.vendorName ?? vendor?.vendorName}
                    onChange={handleChange}
                    label="Name"
                  />
                </Box>
                <Box m={0} width="100%" display="flex">
                  {vendor?.VendorMeta?.sustainableCertifications?.map(
                    (certification) => (
                      <Box
                        key={certification.title}
                        mb={1}
                        mr={1}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Chip
                          label={certification.title}
                          sx={{
                            backgroundColor: COLORS.LIGHT_GRAY,
                          }}
                        />
                      </Box>
                    )
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box m={0} pt={4}>
            <Box pb={4}>
              <InputLabel pb={2}>Tags</InputLabel>
              <Box pt={2}>
                {vendor?.tags?.map((tag) => (
                  <Box key={tag} pb={2} display="inline-flex">
                    <Chip
                      label={tag}
                      sx={{
                        backgroundColor: COLORS.OFFSET,
                        display: "inline-block",
                        marginRight: "8px",
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <TextField
                sx={{
                  ...CENTER_COLUMN_STYLES,
                }}
                value={changes.tags ?? vendor?.tags?.join(", ")}
                onChange={handleChange}
                name="tags"
              />
            </Box>
            <Box pb={4}>
              <InputLabel>Description</InputLabel>
              <Box
                as="textarea"
                style={{
                  ...CENTER_COLUMN_STYLES,
                  height: "100px",
                }}
                maxRows={3}
                onChange={handleChange}
                name="description"
                value={changes?.description ?? vendor?.VendorMeta?.description}
              />
            </Box>
            <Box pb={4}>
              <InputLabel>Long Description ( for web )</InputLabel>
              <Box
                as="textarea"
                style={{
                  ...CENTER_COLUMN_STYLES,
                  height: "100px",
                }}
                maxRows={3}
                onChange={handleChange}
                name="longDescription"
                value={
                  changes?.longDescription ??
                  vendor?.VendorMeta?.longDescription
                }
              />
            </Box>
            <Box pb={4}>
              <InputLabel>Sustainability Description</InputLabel>
              <Box
                as="textarea"
                style={{
                  ...CENTER_COLUMN_STYLES,
                  height: "100px",
                }}
                maxRows={3}
                onChange={handleChange}
                name="sustainableReasonDescription"
                value={
                  changes?.sustainableReasonDescription ??
                  vendor?.VendorMeta?.sustainableReasonDescription
                }
              />
            </Box>
            <Box pb={4}>
              <InputLabel>Website URL</InputLabel>
              <TextField
                sx={{
                  ...CENTER_COLUMN_STYLES,
                }}
                value={changes?.websiteURL ?? vendor?.VendorMeta?.websiteURL}
                onChange={handleChange}
                name="websiteURL"
                inputProps={{
                  type: "url",
                }}
              />
            </Box>
            <Box pb={4}>
              <InputLabel>Search terms</InputLabel>
              <TextField
                sx={{
                  ...CENTER_COLUMN_STYLES,
                }}
                value={changes?.searchTerms ?? vendor?.VendorMeta?.searchTerms}
                onChange={handleChange}
                name="searchTerms"
              />
            </Box>
            <Box pb={4}>
              <InputLabel>Learn more URL</InputLabel>
              <TextField
                sx={{
                  ...CENTER_COLUMN_STYLES,
                }}
                value={
                  changes?.emissionsLearnMoreURL ??
                  vendor?.VendorMeta?.emissionsLearnMoreURL
                }
                onChange={handleChange}
                name="emissionsLearnMoreURL"
                inputProps={{
                  type: "url",
                }}
              />
            </Box>
          </Box>
        </VendorContent>
        <VendorSidebar>
          <>
            <Box
              pb={1}
              display="flex"
              columnGap={2}
              flexDirection="row"
              maxWidth="250px"
            >
              <Box m={0} as="h4">
                {!vendorId ? "Creating" : "Editing"}{" "}
                {changes?.vendorName ?? vendor?.vendorName}
              </Box>
            </Box>
            {hasChanges && (
              <Box m={0} as="small">
                (Unsaved changes)
              </Box>
            )}
            <Box pb={2} display="flex" columnGap={2} flexDirection="row">
              <Button
                variant="contained"
                disabled={
                  !hasChanges ||
                  isVendorUpdating ||
                  isCertificationUpdating ||
                  error
                }
                onClick={onSaveChanges}
                size="small"
              >
                Save
              </Button>
              <Button
                variant="outlined"
                disabled={!hasChanges || isVendorUpdating}
                onClick={clearChanges}
                size="small"
              >
                Cancel
              </Button>
              {isVendorUpdating && <CircularProgress />}
            </Box>

            {error && (
              <Box
                borderRadius={2}
                p={1}
                my={1}
                sx={{
                  color: COLORS.ERROR,
                  backgroundColor: COLORS.ERROR_BG,
                }}
              >
                <small>{error?.message}</small>
              </Box>
            )}
          </>
          <Box mb={2}>
            <SidebarModule title="Vendor Settings" isExpanded>
              <SidebarModuleCheck
                label="Sustainable"
                isChecked={
                  changes.isSustainableVendor ??
                  vendor?.isSustainableVendor ??
                  false
                }
                onChange={handleChange}
                name="isSustainableVendor"
                description="This will allow the vendor, and transaction with the vendor to be sustainable. This also helps dictate if the brand should be shown in search and discovery"
              />
              <SidebarModuleCheck
                label="Hyper-local"
                isChecked={
                  changes.isHyperLocal ?? vendor?.isHyperLocal ?? false
                }
                onChange={handleChange}
                name="isHyperLocal"
                description="Hyper local is to be added to brands that are specific to a location. This flag will also prohibit the vendor from being recommended."
              />
              <SidebarModuleCheck
                label="Recommended Essential"
                isChecked={
                  changes.isRecommendedEssential ??
                  vendor?.isRecommendedEssential ??
                  false
                }
                onChange={handleChange}
                name="isRecommendedEssential"
                description="This will allow the vendor to be recommended as an essential vendor and show up for recommendation when no transaction recommendations are found."
              />
              <SidebarModuleCheck
                label="Public"
                isChecked={
                  changes.visibility
                    ? changes.visibility === "public"
                    : vendor?.VendorMeta?.visibility === "public"
                }
                onChange={() => {
                  handleChange({
                    target: {
                      name: "visibility",
                      value:
                        changes.visibility === "public" ? "private" : "public",
                    },
                  });
                }}
                name="visibility"
                description="This will allow the vendor to be shown in search and discovery, is not public internal users still can find this brand."
              />
            </SidebarModule>
            <SidebarModule title="Carbonizer Settings">
              <SubcategorySelect
                value={changes.joroSubcategoryId ?? vendor.joroSubcategoryId}
                onChange={handleChange}
              />
              <SidebarModuleCheck
                label="Clean Name"
                isChecked={
                  changes.isCleanVendorName ??
                  vendor?.isCleanVendorName ??
                  false
                }
                onChange={handleChange}
                name="isCleanVendorName"
                description="This denotes this is the proper name for the vendor. If false a corrected vendor can be mapped to."
              />
              {!vendor?.isCleanVendorName && (
                <VendorSelect
                  value={changes.correctedVendorId ?? vendor.correctedVendorId}
                  onChange={handleChange}
                  label="Corrected Vendor"
                  name="correctedVendorId"
                />
              )}
              <SidebarModuleCheck
                label="Matchable"
                isChecked={
                  changes.isMatchableVendor ??
                  vendor?.isMatchableVendor ??
                  false
                }
                onChange={handleChange}
                name="isMatchableVendor"
              />
            </SidebarModule>
            <SidebarModule title="Genie Settings">
              {!vendorId ? (
                <Typography variant="body6">
                  Create vendor before adding to Genie
                </Typography>
              ) : (
                <Box marginBottom={2}>
                  {vendor?.Brand ? (
                    <Typography>
                      {`Included in Genie on ${dayjs(
                        vendor?.Brand.createdAt
                      ).format("MM/DD/YYYY")}`}
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={async () => {
                        await updateVendor({
                          vendorId: vendor?.id,
                          update: { shouldCreateBrand: true },
                        });
                        await refetch();
                      }}
                    >
                      Include in Genie
                    </Button>
                  )}
                </Box>
              )}
            </SidebarModule>
            <SidebarModule title="Certifications">
              {!vendorId ? (
                <Typography variant="body6">
                  Create vendor before adding certifications
                </Typography>
              ) : (
                <>
                  <CertificationButton
                    onSelectCertification={async (certificate) => {
                      await createVendorCertification({
                        certificationId: certificate.value,
                      });
                      await refetch();
                    }}
                    mb={2}
                    exclude={vendor?.VendorCertifications?.map(
                      (cert) => cert?.Certification?.id
                    )}
                  />
                  {vendor?.VendorCertifications?.map((vendorCertification) => (
                    <CertificationModule
                      key={vendorCertification.id}
                      onChange={handleChange}
                      title={vendorCertification?.Certification?.title}
                      id={vendorCertification.id}
                      certificationId={vendorCertification?.Certification?.id}
                      certificationURL={
                        changes[
                          `certificationURL:${vendorCertification?.Certification?.id}`
                        ] ?? vendorCertification.certificationURL
                      }
                      certificationStatus={
                        changes[
                          `certificationStatus:${vendorCertification?.Certification?.id}`
                        ] ?? vendorCertification.certificationStatus
                      }
                    />
                  ))}
                </>
              )}
            </SidebarModule>
            <SidebarModule title="Offers">
              {!vendorId ? (
                <Typography variant="body6">
                  Create vendor before adding offers
                </Typography>
              ) : (
                <>
                  {vendor?.VendorOffers?.map((offer) => (
                    <Box
                      key={offer.id}
                      padding={2}
                      mb={2}
                      sx={{
                        border: `1px solid ${COLORS.WARM_GRAY}`,
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        selectOffer(offer.id);
                      }}
                      role="button"
                    >
                      <Typography variant="h3" fontWeight="bold">
                        {offer.offerTitle}
                      </Typography>
                      {offer.offerDescription && (
                        <Typography>{offer.offerDescription}</Typography>
                      )}
                    </Box>
                  ))}
                  <Box marginBottom={2}>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        selectOffer("new");
                      }}
                    >
                      + {hasNewOffer ? "Edit new" : "Add"} Offer
                    </Button>
                  </Box>
                </>
              )}
            </SidebarModule>
          </Box>
        </VendorSidebar>
      </VendorLayout>
      <Modal open={!!selectedOffer}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100vw", height: "100vh" }}
          onClick={closeOffer}
          data-testid="offer-modal"
        >
          <Card
            onClick={(e) => e.stopPropagation()}
            sx={{ maxHeight: "80vh", overflowY: "scroll" }}
          >
            {(() => {
              const offer = vendor?.VendorOffers?.find(
                (offer) => offer.id.toString() === selectedOffer
              );
              const isNewOffer = selectedOffer === "new";
              const id = offer?.id || selectedOffer;

              return offer || isNewOffer ? (
                <OfferModal
                  onChange={handleChange}
                  onImageUpload={onImageUpload}
                  id={id ?? selectOffer}
                  offerTitle={changes[`offerTitle:${id}`] ?? offer?.offerTitle}
                  offerDescription={
                    changes[`offerDescription:${id}`] ?? offer?.offerDescription
                  }
                  offerUrl={changes[`offerUrl:${id}`] ?? offer?.offerUrl}
                  offerEndDate={
                    changes[`offerEndDate:${id}`] ?? offer?.offerEndDate
                  }
                  offerStartDate={
                    changes[`offerStartDate:${id}`] ?? offer?.offerStartDate
                  }
                  offerImage={
                    changes[`offerImage:${id}`] ??
                    offer?.offerImage ??
                    vendor?.VendorMeta?.imageURL
                  }
                />
              ) : (
                <Box padding={6}>
                  <Typography variant="h3" fontWeight="bold">
                    Offer not found
                  </Typography>
                </Box>
              );
            })()}
          </Card>
        </Box>
      </Modal>
      <Modal open={!!openMemotesterModal}>
        <MemoTester
          vendorId={vendorId}
          vendorName={vendor.vendorName}
          requestId={memoTesterRequestData?.requestId}
          closeModal={() => setOpenMemotesterModal(false)}
        />
      </Modal>
    </>
  );
};
