import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useUploadImage } from "../hooks/useUploadImage";
import { COLORS } from "../constants/colors";
import { resolveUrl } from "./ImageUploader/utils";

const ratioStringToNumber = (ratio) => {
  const [width, height] = ratio.split(":");
  return width / height;
};

export const ImageUpload = ({
  image,
  onChange,
  name = "imageURL",
  imageAlt = "uploaded image",
  ratio: ratioStr = "16:9",
  sx = {},
}) => {
  const ratio = ratioStringToNumber(ratioStr);
  const { mutateAsync: uploadImage, isLoading } = useUploadImage();
  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    const imageType = e.target.name;
    const resp = await uploadImage({ imageType, image: file });
    onChange({
      target: {
        name,
        value: resp.assetUrl,
      },
    });
  };
  return (
    <Box
      sx={{
        borderRadius: "8px",
        ...sx,
        border: `1px solid ${COLORS.WARM_GRAY}`,
        overflow: "hidden",
        position: "relative",
        "::after": {
          display: "block",
          content: '""',
          // based on ratio
          paddingBottom: `${100 / ratio}%`,
        },
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        as="input"
        type="file"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "0",
          cursor: "pointer",
          zIndex: "2",
        }}
        onChange={onImageUpload}
        accept="image/jpeg"
        name="imageURL"
      />
      {image ? (
        <Box
          as="img"
          key={image}
          src={resolveUrl(image, {
            auto: "format",
            width: 1200,
            height: 1200 / ratio,
            fit: "max",
          })}
          alt={imageAlt}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            zIndex: "1",
          }}
        />
      ) : (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: "1",
            color: COLORS.WARM_GRAY,
          }}
        >
          <Box as="small" sx={{ textAlign: "center" }}>
            Drop image here
          </Box>
        </Box>
      )}
    </Box>
  );
};
