import React, { Children } from "react";
import { PageLayout } from "../Page";
import Box from "@mui/material/Box";
import { COLORS } from "../../constants/colors";

const CENTER_COLUMN_STYLES = {
  width: "100%",
};

export const VendorHeader = () => null;
export const VendorContent = () => null;
export const VendorSidebar = () => null;

export const VendorLayout = ({ children }) => {
  const headerContent =
    Children.toArray(children).find((child) => child.type === VendorHeader)
      ?.props?.children ?? null;
  const mainContent =
    Children.toArray(children).find((child) => child.type === VendorContent)
      ?.props?.children ?? null;
  const sidebarContent =
    Children.toArray(children).find((child) => child.type === VendorSidebar)
      ?.props?.children ?? null;

  return (
    <Box display="flex" flexDirection="row" position="relative" flex={1}>
      <Box flex={1}>
        <Box
          sx={{
            position: "sticky",
            backgroundColor: COLORS.BEIGE,
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            ...CENTER_COLUMN_STYLES,
            top: "0",
            zIndex: "10",
          }}
          pt={2}
          pb={2}
          ml={2}
        >
          {headerContent}
        </Box>
        <PageLayout sx={{ ...CENTER_COLUMN_STYLES }}>
          <Box sx={{ ...CENTER_COLUMN_STYLES }}>{mainContent}</Box>
        </PageLayout>
      </Box>
      <Box
        p={2}
        mt={13}
        ml={2}
        sx={{
          position: "sticky",
          top: 0,
          height: "min-content",
        }}
      >
        {sidebarContent}
      </Box>
    </Box>
  );
};
