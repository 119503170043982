import React, { useCallback } from "react";
import {
  Outlet,
  useSearchParams,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Divider,
  debounce,
} from "@mui/material";
import { COLORS } from "../../constants/colors";
import { useVendors } from "../../hooks/useVendors";

export function Vendors() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const search = searchParams.get("search") ?? "";
  const hasSustainableOnly = searchParams.get("sustainable") ?? undefined;
  const hasCleanNameOnly = searchParams.get("cleanName") ?? undefined;
  const { data, isLoading } = useVendors({
    search,
    limit: 100,
  });
  const vendors = (data?.vendors ?? []).filter((vendor) => {
    if (hasSustainableOnly) {
      return vendor.isSustainableVendor;
    }
    if (hasCleanNameOnly) {
      return vendor.isCleanVendorName;
    }
    return true;
  });

  const resetFilters = () => {
    setSearchParams(
      {
        search,
      },
      { replace: true }
    );
  };

  const setFilter = (filter) => {
    setSearchParams(
      {
        search,
        ...filter,
      },
      { replace: true }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(debounce(setSearchParams, 500), [
    setSearchParams,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          padding: "4px",
          display: "flex",
          height: "100vh",
          minWidth: "330px",
          overflowY: "hidden",
          flexDirection: "column",
          width: "20vw",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: COLORS.WHITE,
            borderRadius: "4px",
            position: "relative",
            overflowY: "scroll",
            boxShadow: `inset 0px 0px 0px 1px ${COLORS.LIGHT_GRAY}`,
          }}
          pb={2}
          px={2}
        >
          <Box
            sx={{
              position: "sticky",
              top: 1,
              zIndex: 1,
              backgroundColor: COLORS.WHITE,
            }}
          >
            <Box display="flex" py={2}>
              <TextField
                defaultValue={search}
                onChange={(event) => {
                  onSearch(
                    {
                      search: event.target.value,
                      ...(hasSustainableOnly && {
                        sustainable: hasSustainableOnly,
                      }),
                      ...(hasCleanNameOnly && { cleanName: hasCleanNameOnly }),
                    },
                    { replace: true }
                  );
                }}
                sx={{ width: "100%" }}
                label="Search"
                size="small"
                variant="outlined"
                name="search"
              />
              <Box pl={2}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => {
                    navigate(`/vendors/new?${searchParams.toString()}`);
                  }}
                >
                  +
                </Button>
              </Box>
            </Box>
            <Box flexDirection="row" display="flex" alignItems="center">
              <ButtonGroup
                size="small"
                sx={{
                  display: "flex",
                  flex: 1,
                  button: { flexGrow: 1 },
                }}
              >
                <Button
                  onClick={resetFilters}
                  variant={
                    hasSustainableOnly || hasCleanNameOnly
                      ? "outlined"
                      : "contained"
                  }
                  flex={1}
                >
                  All
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ sustainable: true });
                  }}
                  variant={hasSustainableOnly ? "contained" : "outlined"}
                >
                  Sustainable
                </Button>
                <Button
                  onClick={() => {
                    setFilter({ cleanName: true });
                  }}
                  variant={hasCleanNameOnly ? "contained" : "outlined"}
                >
                  Clean
                </Button>
              </ButtonGroup>
            </Box>
            <Box display="flex" py={1} flexDirection="row" alignItems="center">
              <small>Showing {vendors.length} results</small>
              {isLoading && (
                <Box pl={2}>
                  <CircularProgress size={15} />
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" as="ul">
            {vendors.map((vendor) => (
              <Box key={vendor.id} as="li">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  py={2}
                  px={2}
                  sx={{
                    backgroundColor:
                      id === vendor.id.toString()
                        ? COLORS.LIGHT_GRAY
                        : COLORS.WHITE,
                    borderRadius: "4px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                  >
                    <Box
                      as="img"
                      src={vendor.VendorMeta?.avatarURL}
                      sx={{
                        width: "50px",
                        height: "50px",
                        objectFit: "contain",
                        borderRadius: "50%",
                        border: `1px solid ${COLORS.WARM_GRAY}`,
                      }}
                      mr={2}
                    />
                    <Box display="flex" flexDirection="column">
                      <Link to={`/vendors/${vendor.id}?search=${search}`}>
                        {vendor.vendorName}
                      </Link>
                      <small>
                        {`Category: ${vendor.JoroSubcategory?.joroSubcategory ?? "N/A"}`}
                      </small>
                      <small>
                        {`ID: ${vendor.id}`}
                      </small>
                    </Box>
                  </Box>
                  {vendor.isCleanVendorName ? (
                    <big>{"✨"}</big>
                  ) : null}
                  {vendor.correctedVendorId ? (
                    <big>{"🔗"}</big>
                  ) : null}
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Container>
        <Outlet key={id} />
      </Container>
    </Box>
  );
}
