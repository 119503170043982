import {
  TextField,
  Box,
  Modal,
  Button,
  Card,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { v4 } from "uuid";
import React, { useState } from "react";
import { COLORS } from "constants/colors";
import { SubcategorySelect } from "components/Vendor/SubcategorySelect";
import CloseIcon from "@mui/icons-material/Close";
import { VendorSelect } from "components/Vendor/VendorSelect";
import { Callout } from "components/Callout";

export const TransactionsForm = ({ values, onChange, subcategories }) => {
  const transactions = values.transactions ?? [];
  const [selectedTransactionId, setSelectedTransactionId] = useState(0);
  const selectedTransaction = transactions.find(
    (transaction) => transaction.id === selectedTransactionId
  );
  const selectedIndex = transactions.indexOf(selectedTransaction);

  return (
    <>
      {transactions.map((transaction) => {
        const matchingSubcategory = subcategories?.find((subcategory) => {
          return subcategory.id === transaction.joroSubcategoryId;
        });
        return (
          <Box
            key={transaction.id}
            sx={{
              backgroundColor: COLORS.LIGHT_GRAY,
              borderColor: COLORS.PERIWINKLE,
              borderRadius: 2,
              paddingY: 2,
              paddingX: 1,
              borderWidth: 1,
              marginBottom: 2,
              position: "relative",
            }}
            onClick={() => setSelectedTransactionId(transaction.id)}
          >
            <Box as="small" sx={{ margin: 0 }}>
              Transaction {transactions.indexOf(transaction) + 1}
            </Box>
            <Box as="h4" sx={{ margin: 0 }}>
              ${transaction.amount ?? 0}
            </Box>
            <Box as="p" sx={{ margin: 0 }}>
              subcategory: {matchingSubcategory?.joroSubcategory ?? "n/a"}
            </Box>
            {transaction.joroSubcategoryId ? (
              <Box as="p" sx={{ margin: 0 }}>
                Has sustainable subcategory:{" "}
                {matchingSubcategory?.isSustainable ? "true" : "false"}
              </Box>
            ) : null}
            <Box as="p" sx={{ margin: 0 }}>
              vendorId: {transaction.vendor ?? "n/a"}
            </Box>
            {transaction.vendor ? (
              <Box as="p" sx={{ margin: 0 }}>
                Has sustainable vendor:{" "}
                {transaction.hasSustainableVendor ? "true" : "false"}
              </Box>
            ) : null}
            {transaction.vendorHasSustainableOption ? (
              <Box as="p" sx={{ margin: 0 }}>
                Is green offering: {transaction.isGreenOffering || "false"}
              </Box>
            ) : null}
            <Button
              onClick={() => {
                setSelectedTransactionId(transaction.id);
                onChange({
                  target: {
                    name: "transactions",
                    value: transactions.filter((t) => t.id !== transaction.id),
                  },
                });
              }}
              variant="contained"
              size="small"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                mr: 1,
                mt: 1,
                borderRadius: 5,
                p: 1,
                minWidth: "unset",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
        );
      })}
      <Button
        onClick={() => {
          const id = v4();
          setSelectedTransactionId(id);
          onChange({
            target: {
              name: `transactions[${transactions.length}]`,
              value: {
                id,
                transactionDate: new Date().toISOString().split("T")[0],
              },
            },
          });
        }}
      >
        Add Transaction
      </Button>
      <Modal open={!!selectedTransaction}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100vw", height: "100vh" }}
        >
          <Box>
            <Button
              variant="text"
              size="small"
              sx={{ marginBottom: "-72px", marginLeft: "-12px" }}
              data-testid="transaction-modal"
              onClick={() => setSelectedTransactionId(null)}
            >
              <CloseIcon />
            </Button>

            <Card
              onClick={(e) => e.stopPropagation()}
              sx={{ maxHeight: "80vh", overflowY: "scroll", padding: 4 }}
            >
              <Callout type="info">
                Editing Transaction &quot;{selectedIndex + 1}&quot;
              </Callout>
              <Box paddingBottom={4} />
              <TextField
                name={`transactions[${selectedIndex}].amount`}
                onChange={onChange}
                value={transactions[selectedIndex]?.amount}
                label="Amount"
                type="number"
                InputProps={{
                  startAdornment: "$ ",
                }}
                sx={{
                  width: "100%",
                  marginBottom: 2,
                }}
              />
              <VendorSelect
                onChange={(e) => {
                  Object.assign(transactions[selectedIndex], {
                    joroSubcategoryId: e.detail?.joroSubcategoryId,
                    offsetPercentage: e.detail?.offsetPercentage,
                    vendorHasSustainableOption: e.detail?.hasSustainableOption,
                    hasSustainableVendor: e.detail?.isSustainableVendor,
                  });
                  onChange(e);
                }}
                width="100%"
                value={transactions[selectedIndex]?.vendor}
                name={`transactions[${selectedIndex}].vendor`}
                label="Vendor"
              />
              <Box
                display="flex"
                flex={1}
                alignSelf={"center"}
                sx={{ width: "100%" }}
              >
                Or
              </Box>
              <SubcategorySelect
                onChange={(e) => {
                  if (
                    e.target.value !==
                    transactions[selectedIndex]?.joroSubcategoryId
                  ) {
                    transactions[selectedIndex].vendor = null;
                  }
                  onChange(e);
                }}
                sx={{
                  width: "100%",
                  marginBottom: 2,
                }}
                name={`transactions[${selectedIndex}].joroSubcategoryId`}
                value={transactions[selectedIndex]?.joroSubcategoryId}
              />
              <InputLabel id={`sustainableChoiceSource${selectedIndex}`}>
                Transaction Date
              </InputLabel>
              <Box
                as="input"
                type="date"
                name={`transactions[${selectedIndex}].transactionDate`}
                onChange={onChange}
                value={transactions[selectedIndex]?.transactionDate}
                sx={{
                  width: "100%",
                  marginBottom: 2,
                  paddingY: 2,
                  paddingX: 1,
                }}
              />
              {transactions[selectedIndex]?.vendorHasSustainableOption ? (
                <FormGroup>
                  <FormControlLabel
                    name={`transactions[${selectedIndex}].isGreenOffering`}
                    checked={
                      transactions[selectedIndex]?.isGreenOffering === "true"
                    }
                    onChange={(e) => {
                      e.target.value = e.target.checked;
                      onChange(e);
                    }}
                    id={`sustainableChoiceSource${selectedIndex}`}
                    control={<Checkbox />}
                    label="Is green offering"
                  />
                </FormGroup>
              ) : null}
              <Box paddingBottom={4} />
              <Callout type="warn">
                Amount, and Vendor or Subcategory is required!
              </Callout>
            </Card>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
